<template>
  <div>
    <div class="mt-3">
      <a-row :gutter="24">
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Altas" quantity-description="Solicitudes" :quantity="altaObject.total"
            :percentage="altaObject.progress" :details="altaObject.details"
            :redirectionTo="altaObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Altas cargas" quantity-description="Solicitudes"
            :quantity="altaCargasObject.total" :percentage="altaCargasObject.progress"
            :details="altaCargasObject.details" :redirectionTo="altaCargasObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Bajas" quantity-description="Solicitudes" :quantity="bajaObject.total"
            :percentage="bajaObject.progress" :details="bajaObject.details"
            :redirectionTo="bajaObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Bajas cargas" quantity-description="Solicitudes"
            :quantity="bajaCargasObject.total" :percentage="bajaCargasObject.progress"
            :details="bajaCargasObject.details" :redirectionTo="bajaCargasObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Modificaciones" quantity-description="Solicitudes"
            :quantity="modificacionObject.total" :percentage="modificacionObject.progress"
            :details="modificacionObject.details" :redirectionTo="modificacionObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Traspasos" quantity-description="Solicitudes"
            :quantity="transferObject.total" :percentage="transferObject.progress" :details="transferObject.details"
            :redirectionTo="transferObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Rechazo" quantity-description="Casos" :quantity="rechazoObject.total"
            :percentage="rechazoObject.progress" :details="rechazoObject.details"
            :redirectionTo="rechazoObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Antecedentes" quantity-description="Casos"
            :quantity="antecedentesObject.total" :percentage="antecedentesObject.progress"
            :details="antecedentesObject.details" :redirectionTo="antecedentesObject.redirectionObject" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card :loading="loading" title="Fuera de tiempo" quantity-description="Casos"
            :quantity="fueraDeTiempoObject.total" :percentage="fueraDeTiempoObject.progress"
            :details="fueraDeTiempoObject.details" :redirectionTo="fueraDeTiempoObject.redirectionObject" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import {
  GET_PERIOD_DATE_FILTER_DASHBOARD,
  SELECTED_COMPANY,
  FETCH_ALL_DATA_DASHBOARD,
  ALL_COMPANIES,
  SELECT_FILTER
} from '@/store/types';
import notification from '@/utils/notifications';
import CollectionAndBillingDetailsCard from '@/components/dashboard/CollectionAndBillingDetailsCard.vue';

export default {
  components: {
    'detail-card': CollectionAndBillingDetailsCard
  },
  setup() {
    // * Store
    const store = useStore();

    // * Filtro de fechas
    const date_filter = computed({
      get() {
        return store.getters[GET_PERIOD_DATE_FILTER_DASHBOARD];
      }
    });

    // * Data por card
    const getObjectParams = computed(() => {
      const obj = {};

      if (selected_companies.value.length != 0) {
        obj['companyId'] = selected_companies.value.join(',');
      }

      if (date_filter.value.length > 0) {
        obj['startDate'] = moment(date_filter.value[0]).format('YYYY-MM-DD');
        obj['endDate'] = moment(date_filter.value[1]).format('YYYY-MM-DD');
      }

      return obj;
    });

    const altaObject = computed(() => {
      const values = card_values.altas;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Ejecutadas',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,7,8', isRequesting: true }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Altas',
          query: getObjectParams.value
        }
      };
    });

    const altaCargasObject = computed(() => {
      const values = card_values.altasCargas;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Ejecutadas',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,7,8', isRequesting: true }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'AltasCargas',
          query: getObjectParams.value
        }
      };
    });

    const bajaObject = computed(() => {
      const values = card_values.bajas;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Ejecutadas',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '8,7,1' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Bajas',
          query: getObjectParams.value
        }
      };
    });

    const bajaCargasObject = computed(() => {
      const values = card_values.bajasCargas;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Ejecutadas',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '8,7,1' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'BajasCargas',
          query: getObjectParams.value
        }
      };
    });

    const modificacionObject = computed(() => {
      const values = card_values.modificacion;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Ejecutadas',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '8,7,1' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Modificaciones',
          query: getObjectParams.value
        }
      };
    });

    const fueraDeTiempoObject = computed(() => {
      const values = card_values.fueradetiempo;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Cerrados',
            total: values.executed,
            state: { state: 5 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,2,7,8,3' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'FueraTiempo',
          query: getObjectParams.value
        }
      };
    });

    const rechazoObject = computed(() => {
      const values = card_values.rechazo;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Cerrados',
            total: values.executed,
            state: { state: 3 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,2,7,8,3' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Rechazo',
          query: getObjectParams.value
        }
      };
    });

    const antecedentesObject = computed(() => {
      const values = card_values.rechazo;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Cerrados',
            total: values.executed,
            state: { state: 5 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,2,7,8,3' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Antecedentes',
          query: getObjectParams.value
        }
      };
    });

    const transferObject = computed(() => {
      const values = card_values.transferencias;
      return {
        progress: Math.round(values.percentage),
        total: values.total,
        details: [
          {
            id: 1,
            description: 'Cerrados',
            total: values.executed,
            state: { state: 5 }
          },
          {
            id: 2,
            description: 'En revisión',
            total: values.review,
            state: { state: '1,2,7,8,3' }
          },
          {
            id: 3,
            description: 'Canceladas',
            total: values.cancelled,
            state: { state: 4 }
          }
        ],
        redirectionObject: {
          name: 'Transfers',
          query: getObjectParams.value
        }
      };
    });

    // Selector de holding, Subholding y compañias
    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    // * Obtencion de data principal
    const card_values = reactive({
      altas: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      altasCargas: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      bajas: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      bajasCargas: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      modificacion: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      fueradetiempo: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      rechazo: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      antecedentes: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      },
      transferencias: {
        percentage: 0,
        total: 0,
        executed: 0,
        review: 0,
        cancelled: 0
      }
    });

    const getPercentages = (data, key) => {
      if (data != undefined) {
        const percentage = isNaN(
          Math.round(((data.executeds + data.canceleds) / data.totals) * 100)
        )
          ? 0
          : ((data.executeds + data.canceleds) / data.totals) * 100;
        const total = data.totals;
        const executed = data.totals
          ? Math.round((data.executeds / data.totals) * 100)
          : 0;
        const in_review = data.totals
          ? Math.round(((data.pendings + data.createds) / data.totals) * 100)
          : 0;
        const cancelled = data.totals
          ? Math.round((data.canceleds / data.totals) * 100)
          : 0;

        card_values[key].percentage = percentage;
        card_values[key].total = total;
        card_values[key].executed = executed;
        card_values[key].review = in_review;
        card_values[key].cancelled = cancelled;
      }
    };

    const loading = ref(false);
    const getMainData = async () => {
      loading.value = true;
      try {
        const payload = {};

        // tomo los ids de las compañias seleccionadas
        if (selected_companies.value.length > 0) {
          payload.company = selected_companies.value.join(',');
        }

        // Tomo las fechas del filtro de fechas
        if (date_filter.value.length > 0 && date_filter.value != undefined) {
          payload['startDate'] = moment(date_filter.value[0]).format(
            'YYYY-MM-DD'
          );
          payload['endDate'] = moment(date_filter.value[1]).format(
            'YYYY-MM-DD'
          );
        }

        // lo transformo en parametros para la consulta
        let params = '';
        Object.keys(payload).forEach((key, index) => {
          if (index === 0) {
            params += `?${key}=${payload[key]}`;
          } else {
            params += `&${key}=${payload[key]}`;
          }
        });

        const response = await store.dispatch(FETCH_ALL_DATA_DASHBOARD, params);

        const {
          alta,
          altaCargas,
          baja,
          bajaCargas,
          modificacion,
          cases,
          transferencia
        } = response.data;

        getPercentages(alta, 'altas');
        getPercentages(altaCargas, 'altasCargas');
        getPercentages(baja, 'bajas');
        getPercentages(bajaCargas, 'bajasCargas');
        getPercentages(modificacion, 'modificacion');
        getPercentages(cases.outTime, 'fueradetiempo');
        getPercentages(cases.rechazo, 'rechazo');
        getPercentages(cases.antecedentes, 'antecedentes');
        getPercentages(transferencia, 'transferencias');
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => date_filter.value,
      (new_dates) => {
        if (new_dates.length != undefined) {
          getMainData();
        }
      }
    );

    onMounted(() => {
      getMainData();
    });

    return {
      selected_companies,
      card_values,
      altaObject,
      altaCargasObject,
      getObjectParams,
      date_filter,
      bajaObject,
      bajaCargasObject,
      modificacionObject,
      fueraDeTiempoObject,
      rechazoObject,
      antecedentesObject,
      loading,
      transferObject
    };
  }
};
</script>
